import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";
import { uniq } from 'lodash';
import Loading from "components/Loading";

import Emitter from "helpers/emitter_helper";
import { SINGLE_SCHEDULE_NAME } from "config";

class Provincia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: null,
      validated: false
    };
  }

  isValidated() {
    return this.state.validated;
  }

  componentDidMount() {
    Emitter.on('PREVIOUS_BUTTON_CLICK', (value) => {
      if (value) {
        this.setState({ state: null });
      }
    });
  }

  componentWillUnmount() {
    Emitter.off('PREVIOUS_BUTTON_CLICK');
  }

  setProvince(event) {
    this.setState({ state: event.target.value, validated: true }, () => {
      Emitter.emit('PROVINCE_SELECTED', event.target.value);
      Emitter.emit('SET_SCHEDULE', SINGLE_SCHEDULE_NAME);
      Emitter.emit('SCHEDULE_SELECTED', SINGLE_SCHEDULE_NAME);
      Emitter.emit('NEXT_BUTTON_CLICK', true);
    });    
  }

  render() {
    let states = [];
    if (this.props.branches)
      states = uniq(this.props.branches.map((b)=> b.estado)).sort((a, b) => a.localeCompare(b)).filter((s)=>s);
    return (
      <React.Fragment>
        <Row className="justify-content-center">
          {this.props.loading && (
            <Loading />
          )}
        </Row>
        <Row>
            <Col>
              <h5><span className="step-number">1</span> <span className="step-description">Seleccioná tu Estado</span></h5>
            </Col>
        </Row>
        <Row>
          {states.length>0 && states.map((state) => (
            <Col key={state} md={6} xs={12}>
              <div className="custom-radio mr-3">
                <input id={'state-' + state} name="states" type="radio" value={state} checked={this.state.state === state} onChange={this.setProvince.bind(this)} />
                <label htmlFor={'state-' + state} className="uppercase">{state}</label>
              </div>
            </Col>
          )
          )}
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const schedule = state.Home.schedules.find(s => s.name === SINGLE_SCHEDULE_NAME);
  const branches = schedule?.branches ? schedule.branches : [];
  return {
    branches
  };
}

export default connect(mapStateToProps, {
}, null, { forwardRef: true })(Provincia);

