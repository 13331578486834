import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col, Button } from "reactstrap";

import "flatpickr/dist/themes/light.css";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import Flatpickr from "react-flatpickr";

import moment from "moment";

import { filter, find, sortBy } from "lodash";

import Loading from "components/Loading";

import Emitter from "helpers/emitter_helper";

import { getDates } from "store/actions";

import rightCaret from "../../assets/images/right-caret-white.svg";

class Fecha extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validated: false,
      branchId: null,
      hours: [],
      date: null,
      scheduleName: null,
      hour: null,
      startAt: null,
      endAt: null,
      key: 1
    };
  }

  componentDidMount() {
    Emitter.on('BRANCH_SELECTED', (obj) => {
      this.setState({ branchId: obj.branch });

      obj.schedules.forEach((s) => {
        this.props.getDates(s.name, s.id, obj.branch, moment().format('YYYY-MM-DDTHH:mm:ssZZ'));
      });
    });

    Emitter.on('RESET', (value) => {
      this.setState({ key: this.state.key + 1 });
    });
    Emitter.on('SET_SCHEDULE', (value) => {
      if (value) {
        this.setState({ scheduleName: value });
      }
    });

  }

  componentWillUnmount() {
    Emitter.off('BRANCH_SELECTED');
    Emitter.off('RESET');
    Emitter.off('SET_SCHEDULE');
  }

  //eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props !== prevProps && !this.props.enabledDates.length) {
      this.setState({ hours: [], date: null, hour: null, startAt: null, endAt: null });
    }
  }

  isValidated() {
    return this.state.startAt && this.state.endAt;
  }

  handleMonthChange(selectedDates, dateStr, instance) {
    if (!this.props.loading) {
      this.props.getDates(this.state.scheduleName, this.props.wizardData['Sucursal'].schedule, this.state.branchId, moment(instance.currentYear + '-' + (instance.currentMonth + 1) + '-01', 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ssZZ'));
    }

    this.setState({ hours: [], startAt: null, endAt: null, date: null, hour: null });
  }

  handleYearChange(selectedDates, dateStr, instance) {
    if (!this.props.loading) {
      this.props.getDates(this.state.scheduleName, this.props.wizardData['Sucursal'].schedule, this.state.branchId, moment(instance.currentYear + '-' + (instance.currentMonth + 1) + '-01', 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ssZZ'));
    }

    this.setState({ hours: [], startAt: null, endAt: null, date: null, hour: null });
  }

  selectDate(date) {
    let selected = moment(date.date[0]).format('YYYY-MM-DD');
    let filtered = filter(this.props.dates, { formattedDate: selected });
    
    let hours = sortBy(filtered.filter((h) => { return h.availability > 0 }).map((f) => {
      f.formattedHour = moment(f.zonedStartDate).format('HH:mm');

      return f;
    }), 'formattedHour');

    this.setState({ hours, date, hour: null });

    if (window.innerWidth < 400) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

  setHour(event) {
    let hour = find(this.state.hours, { formattedHour: event.target.value });
    
    this.setState({ 
      startAt: moment(hour.zonedStartDate).format('YYYY-MM-DDTHH:mm:ssZZ'), 
      endAt: moment(hour.zonedStartDate).add(hour.defaultDuration, 'minutes').format('YYYY-MM-DDTHH:mm:ssZZ'),
      hour: event.target.value
    });
  }

  render() {
    return (
      <React.Fragment>
        <Row>
            <Col>
              <h5><span className="step-number">3</span> <span className="step-description">Seleccioná el día y horario de tu cita</span></h5>
            </Col>
        </Row>
        <Row className="mt-3">
          <Col className="pl-0 ml-2" md={{ size: 3 }} xs={{ size: 12 }}>
            <Flatpickr
              key={this.state.key}
              className="form-control d-block"
              options={{
                locale: Spanish,
                inline: true,
                enable: this.props.enabledDates,
                monthSelectorType: 'static',
                prevArrow: '<img src="/icons/left-caret.svg" />',
                nextArrow: '<img src="/icons/right-caret.svg" />',
              }}
              onChange={date => {
                this.selectDate({ date });
              }}
              onMonthChange={(selectedDates, dateStr, instance) => this.handleMonthChange(selectedDates, dateStr, instance) }
              onYearChange={(selectedDates, dateStr, instance) => this.handleYearChange(selectedDates, dateStr, instance)}
            />
          </Col>
          <Col xs={12} md={{ size: 7, offset: 1 }}>
            <Row>
              <Col className="hour-selection">
                {this.props.loading ?
                  <Loading />
                  :
                  <Row className="hours">
                    {
                      this.state.hours.map((hour, index) => (
                        <Col className="px-0" key={index} md={3} xs={3}>
                          <div className={this.state.hour == hour.formattedHour ? 'custom-radio selected' : 'custom-radio'}>
                            <input id={'date-' + index} name="hours" type="radio" checked={this.state.hour == hour.formattedHour} value={hour.formattedHour} onChange={this.setHour.bind(this)} />
                            <label htmlFor={'date-' + index}>{hour.formattedHour}</label>
                          </div>
                        </Col>
                      ))
                    }
                    {
                      ((!this.state.hours.length && this.state.date) || !this.props.enabledDates.length) && (
                        <Col className="px-xs-0 mt-2 no-hours">
                          No hay horarios disponibles.
                        </Col>
                      )
                    }
                  </Row>
                }
              </Col>
            </Row>
            <Row className="next-step">
            { this.state.hour ?
              <Col>
                <Button
                  className="btn-advance"
                  onClick={() => Emitter.emit('NEXT_BUTTON_CLICK', true) }
                >
                  Avanzar a Datos Personales <img src={rightCaret} />
                </Button>
              </Col>
              : undefined }
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { loading, enabledDates, dates, error } = state.Home;
  return {
    loading, enabledDates, dates, error
  };
}

export default connect(mapStateToProps, {
  getDates
}, null, { forwardRef: true })(Fecha);


