import axios from "axios";

//apply base url for axios
const BASE_URL = process.env.REACT_APP_BASE_URL || "/api/appointments/";
const API_TOKEN = process.env.REACT_APP_API_TOKEN;

const axiosApi = axios.create({
  baseURL: BASE_URL,
});

axiosApi.interceptors.request.use(function (config) {
  // solo para desarrollo local sin el reverse proxy que agrega el token
  // en prod no hay que setear REACT_APP_API_TOKEN
  if (API_TOKEN) {
    config.headers["x-api-token"] = API_TOKEN;
  }
  
  return config;
});

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
