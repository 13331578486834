import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col, Card, CardBody } from "reactstrap";

import { find, filter } from "lodash";

import moment from "moment";

import Emitter from "helpers/emitter_helper";

import Loading from "components/Loading";

import health from "../../assets/images/health.svg";
import calendar from "../../assets/images/calendar.svg";
import clock from "../../assets/images/clock.svg";
import location from "../../assets/images/location.svg";
import pencil from "../../assets/images/pencil.svg";
import { SINGLE_SCHEDULE_NAME } from "config";

class Confirmacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tramite: null,
      sucursal: null,
      fecha: '',
      hora: ''
    };
  }

  componentDidMount() {
    Emitter.on('DATA_COMPLETED', (value) => {
      if (value) {
        const tramites = filter(this.props.schedules, (s) => {
          return s.name == SINGLE_SCHEDULE_NAME;
        }); 
        const tramite = filter(tramites, (s) => {
          return find(s.branches, { id: this.props.wizardData['Sucursal'].branch });
        })[0];
        const sucursal = find(tramite.branches, { id: this.props.wizardData['Sucursal'].branch });
        const date = moment(this.props.wizardData['Fecha'].startAt);

        this.setState({ tramite, sucursal, fecha: date.format('DD/MM/YYYY'), hora: date.format('HH:mm') });
      }
    });
  }

  componentWillUnmount() {
    Emitter.off('DATA_COMPLETED');
    Emitter.off('GO_TO_STEP');
  }

  goToStep(step) {
    Emitter.emit('GO_TO_STEP', step);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.loading ?
          <Row>
            <Col className="d-flex justify-content-center">
              <Loading />
            </Col>
          </Row>
          : <>
          <Row>
            <Col>
              <h5><span className="step-description">Confirmación del cita</span></h5>
            </Col>
          </Row>
          <Card>
            <CardBody className="confirmation-card">
              <Row>
                <Col xs={12} md={6} className="mb-4 mb-md-0">
                  <div>
                    <h5>Tu cita:</h5>
                  </div>

                  <Row className="flex-column">
                    <Col xs={12}>
                      <div className="data-turno">
                        <img src={health} className="img-fluid mr-2" /><label>{this.state.tramite?.name}</label><img src={pencil} onClick={() => this.goToStep(1) } className="ml-2 img-fluid edit" />
                      </div>
                    </Col>

                    <Col xs={12}>
                      <div className="data-turno">
                        <img src={calendar} className="img-fluid mr-2" /><label>{this.state.fecha}</label><img src={pencil} onClick={() => this.goToStep(3) } className="ml-2 img-fluid edit" />
                      </div>
                    </Col>

                    <Col xs={12}>
                      <div className="data-turno">
                        <img src={clock} className="img-fluid mr-2" /><label>{this.state.hora} HS.</label><img src={pencil} onClick={() => this.goToStep(3) } className="ml-2 img-fluid edit" />
                      </div>
                    </Col>

                    <Col xs={12}>
                      <div className="data-turno">
                        <img src={location} className="img-fluid mr-2" /><label>{this.state.sucursal?.direccion}</label><img src={pencil} onClick={() => this.goToStep(2) } className="ml-2 img-fluid edit" />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6}>
                  <div>
                    <h5>Datos del paciente:</h5>
                  </div>

                  <Row className="flex-column">
                    <Col xs={12}>
                      <div className="data-turno-form">
                        <label>Nombre:</label>
                        <span className="ml-1">{this.props.wizardData['Datos Personales'] ? this.props.wizardData['Datos Personales'].values.firstName : '-'}</span><img src={pencil} onClick={() => this.goToStep(4) } className="ml-2 img-fluid edit" />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="data-turno-form">
                        <label>Apellido:</label>
                        <span className="ml-1">{this.props.wizardData['Datos Personales'] ? this.props.wizardData['Datos Personales'].values.lastName : '-'}</span><img src={pencil} onClick={() => this.goToStep(4) } className="ml-2 img-fluid edit" />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="data-turno-form">
                        <label>Email:</label>
                        <span className="ml-1">{this.props.wizardData['Datos Personales'] ? this.props.wizardData['Datos Personales'].values.email : '-'}</span><img src={pencil} onClick={() => this.goToStep(4) } className="ml-2 img-fluid edit" />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="data-turno-form">
                        <label>Teléfono:</label>
                        <span className="ml-1">{this.props.wizardData['Datos Personales'] ? this.props.wizardData['Datos Personales'].values.phone : '-'}</span><img src={pencil} onClick={() => this.goToStep(4) } className="ml-2 img-fluid edit" />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { schedules, loading } = state.Home;
  return {
    schedules, loading
  };
}

export default connect(mapStateToProps, {
}, null, { forwardRef: true })(Confirmacion);

