import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";

import { filter, find } from "lodash";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import Loading from "components/Loading";

import Emitter from "helpers/emitter_helper";

class Tramite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configSchedule: null,
      schedule: null,
      scheduleName: null,
      schedules: [],
      subSchedules: [],
      validated: false,
      showSubSchedules: false,
      showModal: false,
      showNextStep: true,
      modalDescription: '',
      subScheduleTitle: ''
    };
  }

  isValidated() {
    return this.state.validated;
  }

  componentDidMount() {
    Emitter.on('PROVINCE_SELECTED', (province) => {
      let ss = filter(schedules, (s) => s.provinces.includes(province));

      ss.forEach((s) => {
        const os = find(this.props.schedules, { name: s.name });

        if (os) {
          s.id = os.id;
        }

        if (s.subSchedules) {
          s.subSchedules = filter(s.subSchedules, (css) => css.provinces.includes(province));

          s.subSchedules.forEach((sbs) => {
            const osbs = find(this.props.schedules, { name: sbs.name });
            
            if (osbs) {
              sbs.id = osbs.id;
            }
          });
        }
      });
    
      this.setState({ schedules: ss });
    });

    Emitter.on('PREVIOUS_BUTTON_CLICK', (value) => {
      if (value) {
        this.setState({ showSubSchedules: false, schedule: null });
      }
    });
  }

  componentWillUnmount() {
    Emitter.off('PROVINCE_SELECTED');
    Emitter.off('PREVIOUS_BUTTON_CLICK');
  }

  getIcon(schedule) {
    let s = find(schedules, { name: schedule.name });

    if (s) {
      return '/icons/' + s.icon;
    }
    
    return '';
  }

  setSchedule(event) {
    if (this.state.schedule && this.state.schedule != event.target.value) {
      Emitter.emit('RESET', true);
    }
    
    // Seteamos un trámite temporario, ya que agrupamos por nombre, y el trámite definitivo
    // se va a definir en base a la sucursal 
    const id = parseInt(event.target.value);
    const schedule = find(this.props.schedules, { id: id });
    const s = find(schedules, { name: schedule.name });
    
    if (s && s.needsModal && !s.subSchedules) {
      this.setState({ schedule: id, scheduleName: schedule.scheduleName, showModal: true });
    } else {
      if (s && s.subSchedules) {
        s.subSchedules.forEach((ss) => {
          let oss = find(this.props.schedules, { name: ss.name });

          if (oss) {
            ss.id = oss.id;
          }
        });

        this.setState({
          showSubSchedules: true,
          subSchedules: s.subSchedules,
          subScheduleTitle: s.description
        });
      } else {
        this.nextStep(id, schedule.name);
      }
    }
  }

  setSubSchedule(event) {
    const id = parseInt(event.target.value);
    const subSchedule = find(this.state.subSchedules, { id: id });
    
    if (subSchedule) {
        if (subSchedule.needsModal) {
          this.setState({ showNextStep: true, schedule: id, scheduleName: subSchedule.name, showModal: true, modalDescription: subSchedule.modalDescription });
        } else {
          this.nextStep(id, subSchedule.name);
        }
    } else {
      this.setState({ showNextStep: false, showModal: true, modalDescription: 'Actualmente el servicio no esta disponible.' });
    }
  }

  nextStep(id, name) {
    this.setState({ schedule: id, scheduleName: name, validated: true }, () => {
      Emitter.emit('SET_SCHEDULE', name);
      Emitter.emit('SCHEDULE_SELECTED', name);
      Emitter.emit('NEXT_BUTTON_CLICK', true);
    });
  }

  render() {
    return (
      <React.Fragment>
        <Row className="justify-content-center">
          {this.props.loading && (
            <Loading />
          )}
        </Row>
        {!this.state.showSubSchedules ?
        <>
          <Row>
            <Col>
              <h5><span className="step-number">2</span> <span className="step-description">Seleccioná el servicio que buscás</span></h5>
            </Col>
          </Row>
          <Row>
            {this.state.schedules.map((schedule) => (
              <Col key={schedule.id} md={6} xs={12}>
                <div className="custom-radio mr-3">
                  <input id={'schedule-' + schedule.id} name="schedules" type="radio" value={schedule.id} checked={this.state.schedule === schedule.id} onChange={this.setSchedule.bind(this)} />
                  <label htmlFor={'schedule-' + schedule.id}><img src={this.getIcon(schedule)} className="img-fluid" /> {schedule.name}</label>
                </div>
              </Col>
            )
            )}
          </Row>
        </>
        : 
        <>
          <Row>
            <Col>
              <h5><span className="step-number">2</span> <span className="step-description">{this.state.subScheduleTitle}</span></h5>
            </Col>
          </Row>
          <Row>
          {this.state.subSchedules.map((schedule) => (
            <Col key={schedule.id} md={6} xs={12}>
              <div className="custom-radio mr-3">
                <input id={'schedule-' + schedule.id} name="schedules" type="radio" value={schedule.id} checked={this.state.schedule === schedule.id} onChange={this.setSubSchedule.bind(this)} />
                <label htmlFor={'schedule-' + schedule.id}><img src={this.getIcon(schedule)} className="img-fluid" /> {schedule.name}</label>
              </div>
            </Col>
          )
          )}
          </Row>
        </>}
        {this.state.showModal ?
        <SweetAlert
          title="Información importante"
          confirmBtnBsStyle="advance"
          confirmBtnText="Entendido"
          onConfirm={() => this.setState({ showModal: false }, () => {
            if (this.state.showNextStep) {
              this.nextStep(this.state.schedule, this.state.scheduleName);
            }
          })}
        >
          <p className="modal-info" dangerouslySetInnerHTML={{ __html: this.state.modalDescription }}></p>
        </SweetAlert>
        : undefined }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { loading, schedules, error } = state.Home;
  return {
    loading, schedules, error
  };
}

export default connect(mapStateToProps, {
}, null, { forwardRef: true })(Tramite);

