import React, { Component } from "react";

// Redux
import { connect } from "react-redux";

import { Row, Col } from "reactstrap";

import { find, filter, map, flatten } from "lodash";

import Emitter from "helpers/emitter_helper";

import location from "../../assets/images/location.svg";
import { SINGLE_SCHEDULE_NAME } from "config";

class Sucursal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schedule: null,
      schedules: [],
      branches: [],
      originalBranches: [],
      branch: null,
      validated: false,
      search: '',
      province: ''
    };
  }

  isValidated() {
    return this.state.validated;
  }

  setSchedule (value){
    if (value) {
      let filteredSchedules = filter(this.props.schedules, ['name', value]);
      let filteredBranches = [];

      filteredSchedules.forEach((fs) => {
        filteredBranches.push(map(fs.branches, 'id'));
      });

      filteredBranches = flatten(filteredBranches);
      let branches = filter(this.props.branches, (b) => {
        return filteredBranches.includes(b.id) && b.estado.toLowerCase() == this.state.province.toLowerCase();
      }).map((b) => {
        let branch = b.name.split(':');

        let name = branch[0];

        b.name = name;

        return b;
      });
      this.setState({ branches, originalBranches: branches, schedules: filteredSchedules });
    }
  }

  componentDidMount() {
    Emitter.on('RESET', (value) => {
      if (value) {
        this.setState({ branch: null, validated: false });
      }
    });

    Emitter.on('SCHEDULE_SELECTED', (value) => {
      this.setSchedule(value);
    });

    Emitter.on('PROVINCE_SELECTED', (province) => {
      this.setState({ province }, () => {
        this.setSchedule(SINGLE_SCHEDULE_NAME);
      });
    });

    Emitter.on('PREVIOUS_BUTTON_CLICK', (value) => {
      if (value) {
        this.setState({ search: '', branch: null });
      }
    });
  }

  componentWillUnmount() {
    Emitter.off('RESET');
    Emitter.off('SCHEDULE_SELECTED');
    Emitter.off('PROVINCE_SELECTED');
    Emitter.off('PREVIOUS_BUTTON_CLICK');
  }

  setBranch(event) {
    if (this.state.branch && this.state.branch != event.target.value) {
      Emitter.emit('RESET', true);
    }

    let id = parseInt(event.target.value);

    // Una vez elegida la sucursal, podemos saber realmente el id del trámite
    let schedules = filter(this.state.schedules, (s) => {
      return !!find(s.branches, ['id', id]);
    });
    this.setState({ branch: id, schedule: schedules[0].id, validated: true }, () => {
      Emitter.emit('BRANCH_SELECTED', {
        branch: id,
        schedules: schedules
      });
  
      Emitter.emit('NEXT_BUTTON_CLICK', true);
    });
  }

  searchBranches(event) {
    if (event.target.value) {
      let branches = this.state.originalBranches.filter((b) => { return b.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 || b.direccion && b.direccion.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1 });

      this.setState({ branches, search: event.target.value });
    } else {
      this.setState({ branches: this.state.originalBranches, search: '' });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Row>
            <Col>
              <h5><span className="step-number">2</span> <span className="step-description">Selecciona tu Benamedic más cercano</span></h5>
            </Col>
        </Row>
        <Row className="my-md-3">
          <Col>
            <input type="text" placeholder="Buscar por Benamedic o dirección" className="search form-control" value={this.state.search} onChange={this.searchBranches.bind(this)} /><i className="mdi mdi-magnify icon"></i>
          </Col>
        </Row>
        <Row>
          {this.state.branches.map((branch) => (
            <Col key={branch.id} md={6} xs={12}>
              <div className="custom-radio location">
                <input id={'branch-' + branch.id} name="branches" type="radio" value={branch.id} checked={this.state.branch === branch.id} onChange={this.setBranch.bind(this)} />
                <label htmlFor={'branch-' + branch.id} style={{display: "block"}}>
                  <div>
                    {branch.name}
                  </div>
                  <div>
                    <span><img src={location} className="img-fluid" /> {branch.direccion}</span>
                  </div>
                </label>
              </div>
            </Col>
          ))}
        </Row>
        {!this.state.branches.length && this.state.search.length ?
        <Row className="mt-4">
          <Col>
            <span className="no-results"><strong>No encontramos coincidencias con tu búsqueda.</strong> Por favor, intentá tipeando otras palabras.</span>
          </Col>
        </Row>
        : undefined}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { loading, schedules, branches, error } = state.Home;
  return {
    loading, schedules, branches, error
  };
}

export default connect(mapStateToProps, {
}, null, { forwardRef: true })(Sucursal);

