import React from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button
} from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";
import Emitter from "helpers/emitter_helper";
import check from "../assets/images/check.svg";

const isForwardRefComponent = (component) => {
  return typeof component === 'object' && component['$$typeof'] === Symbol.for('react.forward_ref')
};

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    let width;
    if (this.props.steps.length === 1) {
      width = "100%";
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = 100 / this.props.steps.filter((s) => { return !s.hideStepFromNav }).length;
        } else {
          width = 100 / 3 + "%";
        }
      } else {
        if (this.props.steps.length === 2) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      }
    }
    this.state = {
      currentStep: 0,
      highestStep: 0,
      color: this.props.color !== undefined ? this.props.color : "primary",
      finishButton: this.props.steps.length === 1 ? true : false,
      width: width,
      wizardData:
        this.props.wizardData !== undefined ? this.props.wizardData : {},
      movingTabStyle: {
        transition: "transform 0s"
      },
      progressbarStyle: {
        width: 100 / this.props.steps.length / 2 + "%"
      },
    };
    this.navigationStepChange = this.navigationStepChange.bind(this);
    this.refreshAnimation = this.refreshAnimation.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.finishButtonClick = this.finishButtonClick.bind(this);
  }
  componentDidMount() {
    this.refreshAnimation(0);
    window.addEventListener("resize", this.updateWidth.bind(this));

    Emitter.on('NEXT_BUTTON_CLICK', (value) => {
      if (value) {
        this.nextButtonClick();
      }
    });

    Emitter.on('PREVIOUS_BUTTON_CLICK', (value) => {
      if (value) {
        this.previousButtonClick();
      }
    });

    Emitter.on('GO_TO_STEP', (value) => {
      if (value) {
        this.setState({
          currentStep: value,
          highestStep:
            value > this.state.highestStep ? value : this.state.highestStep,
          finishButton: false
        });
      }
    });
  }
  //eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props !== prevProps && this.props.steps != prevProps.steps) {
      let width;
      if (this.props.steps.length === 1) {
        width = "100%";
      } else {
        if (window.innerWidth < 600) {
          if (this.props.steps.length !== 3) {
            width = 100 / this.props.steps.filter((s) => { return !s.hideStepFromNav }).length;
          } else {
            width = 100 / 3 + "%";
          }
        } else {
          if (this.props.steps.length === 2) {
            width = "50%";
          } else {
            width = 100 / this.props.steps.length + "%";
          }
        }
      }

      this.setState({ width });
      this.refreshAnimation(1);
    }
  }
  componentWillUnmount() {
    this.isCancelled = true;
    window.removeEventListener("resize", this.updateWidth);
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
    Emitter.off('RESET');
    Emitter.off('NEXT_BUTTON_CLICK');
    Emitter.off('PREVIOUS_BUTTON_CLICK');
    Emitter.off('GO_TO_STEP');
  }
  updateWidth() {
    !this.isCancelled &&
      setTimeout(() => this.refreshAnimation(this.state.currentStep), 200);
  }
  async navigationStepChange(key) {
    if (this.props.navSteps) {
      var validationState = true;
      if (this.props.validate && key > this.state.currentStep) {
        for (var i = this.state.currentStep; i < key; i++) {
          if (
            this.refs[this.props.steps[i].stepName].isValidated !== undefined &&
            await this.refs[this.props.steps[i].stepName].isValidated() === false
          ) {
            validationState = false;
            break;
          }
        }
      }
      if (validationState) {
        this.setState({
          wizardData: {
            ...this.state.wizardData,
            [this.props.steps[this.state.currentStep].stepName]: this.refs[
              this.props.steps[this.state.currentStep].stepName
            ].state
          },
          currentStep: key,
          highestStep:
            key > this.state.highestStep ? key : this.state.highestStep,
          finishButton: this.props.steps.length === key + 1 ? true : false
        });
        this.refreshAnimation(key);
      }
    }
  }
  async nextButtonClick() {
    if (
      (this.props.validate &&
        ((this.refs[this.props.steps[this.state.currentStep].stepName]
          .isValidated !== undefined &&
          await this.refs[
            this.props.steps[this.state.currentStep].stepName
          ].isValidated()) ||
          this.refs[this.props.steps[this.state.currentStep].stepName]
            .isValidated === undefined)) ||
      this.props.validate === undefined ||
      !this.props.validate
    ) {
      var key = this.state.currentStep + 1;
      this.setState({
        wizardData: {
          ...this.state.wizardData,
          [this.props.steps[this.state.currentStep].stepName]: this.refs[
            this.props.steps[this.state.currentStep].stepName
          ].state
        },
        currentStep: key,
        highestStep:
          key > this.state.highestStep ? key : this.state.highestStep,
        finishButton: this.props.steps.length === key + 1 ? true : false
      });
      this.props.currentStepChange(key);
      this.refreshAnimation(key);
    }
  }
  previousButtonClick() {
    var key = this.state.currentStep - 1;
    if (key >= 0) {
      this.setState({
        wizardData: {
          ...this.state.wizardData,
          [this.props.steps[this.state.currentStep].stepName]: this.refs[
            this.props.steps[this.state.currentStep].stepName
          ].state
        },
        currentStep: key,
        highestStep:
          key > this.state.highestStep ? key : this.state.highestStep - 1,
        finishButton: this.props.steps.length === key + 1 ? true : false
      });
      this.props.currentStepChange(key);
      this.refreshAnimation(key);
    }
  }
  async finishButtonClick() {
    if (
      (this.props.validate === false &&
        this.props.finishButtonClick !== undefined) ||
      (this.props.validate &&
        ((this.refs[this.props.steps[this.state.currentStep].stepName]
          .isValidated !== undefined &&
          await this.refs[
            this.props.steps[this.state.currentStep].stepName
          ].isValidated()) ||
          this.refs[this.props.steps[this.state.currentStep].stepName]
            .isValidated === undefined) &&
        this.props.finishButtonClick !== undefined)
    ) {
      this.setState(
        {
          progressbarStyle: {
            width: "100%"
          },
          wizardData: {
            ...this.state.wizardData,
            [this.props.steps[this.state.currentStep].stepName]: this.refs[
              this.props.steps[this.state.currentStep].stepName
            ].state
          }
        },
        () => {
          this.props.finishButtonClick(this.state.wizardData);
        }
      );
    }
  }
  refreshAnimation(index) {
    var total = this.props.steps.filter((s) => { return !s.hideStepFromNav }).length;
    var li_width = 100 / total;

    var total_steps =
      this.props.steps !== undefined ? this.props.steps.length : 0;
    var move_distance =
      this.refs.wizard !== undefined
        ? this.refs.navStepsLi.children[0].clientWidth / total_steps
        : 0;
    var index_temp = index;
    var vertical_level = 0;

    var mobile_device = window.innerWidth < 600 && total > 3;

    if (mobile_device) {
      move_distance = this.refs.navStepsLi.children[0].clientWidth / 2;
      index_temp = index % 2;
      li_width = 100 / total;
    }

    this.setState({ width: li_width + "%" });

    var step_width = move_distance;

    move_distance = move_distance * index_temp;

    if (mobile_device) {
      vertical_level = parseInt(index / 2);
      vertical_level = vertical_level * 38;
    }

    var movingTabStyle = {
      width: step_width,
      transform:
        "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
      transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)"
    };
    this.setState({
      movingTabStyle: movingTabStyle,
      progressbarStyle: {
        width: move_distance + step_width / 2
      }
    });
  }

  renderComponent(prop) {
    const { component, stepProps, stepName } = prop;
    if (typeof component === 'function' || isForwardRefComponent(component)) {
      return (
        <prop.component
          ref={stepName}
          wizardData={this.state.wizardData}
          {...stepProps}
        />);
    }

    return (<div ref={stepName}>{component}</div>);
  }

  render() {
    return (
      <div className="wizard-container" ref="wizard">
        <div className="active" data-color={this.state.color}>
          <div
            className={
              this.props.headerTextCenter !== undefined ? "text-center" : ""
            }
            data-background-color={this.state.color}
          >
            <div className="wizard-navigation d-flex align-items-center" ref="navStepsLi">
              <Nav pills>
                {this.props.steps.map((prop, key) => {
                  return (
                    !prop.hideStepFromNav ?
                      <NavItem key={key} style={{ width: this.state.width }}>
                        <NavLink
                          className={classnames(
                            `step-${prop.stepNumber}`,
                            { active: key === this.state.currentStep },
                            { checked: key <= this.state.highestStep }
                          )}
                          onClick={() => this.navigationStepChange(key)}
                        >
                          <div className="step">
                            <div className={classnames(
                            "step-number",
                            { active: key === this.state.currentStep },
                            )}>
                              <span>{prop.stepNumber}</span>
                            </div>
                            <div className={classnames(
                            "step-name",
                            { active: key === this.state.currentStep },
                            )}>{prop.stepLabel}</div>
                          </div>
                        </NavLink>
                      </NavItem>
                    : undefined
                  );
                })}
              </Nav>
            </div>
          </div>
          <div className="wizard-body-inner">
            <TabContent activeTab={this.state.currentStep}>
              {this.props.steps.map((prop, key) => {
                return (
                  <TabPane
                    tabId={key}
                    key={key}
                    className={classnames("fade", {
                      show: this.state.currentStep === key
                    })}
                  >
                    {this.renderComponent(prop)}
                  </TabPane>
                );
              })}
            </TabContent>
          </div>
          <div>
            <div className="confirm-wrapper">
              {this.state.finishButton ? (
                <Button
                  className={classnames("btn-confirm d-inline-block p-0", {
                    [this.props.finishButtonClasses]:
                      this.props.finishButtonClasses !== undefined
                  })}
                  onClick={() => this.finishButtonClick()}
                >
                  {this.props.finishButtonText !== undefined
                    ? this.props.finishButtonText
                    : "Finish"}
                    <img src={check} className="img-fluid ml-3 mb-1" />
                </Button>
              ) : null}
            </div>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    );
  }
}

Wizard.defaultProps = {
  validate: false,
  finishButtonText: "Finish",
  color: "primary",
  progressbar: false
};

Wizard.propTypes = {
  color: PropTypes.oneOf(["primary", "green", "orange", "red", "blue"]),
  finishButtonClasses: PropTypes.string,
  headerTextCenter: PropTypes.bool,
  navSteps: PropTypes.bool,
  validate: PropTypes.bool,
  finishButtonClick: PropTypes.func,
  finishButtonText: PropTypes.node,
  title: PropTypes.node,
  description: PropTypes.node,
  progressbar: PropTypes.bool,
  type: PropTypes.number,
  currentStepChange: PropTypes.func,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepNumber: PropTypes.number.isRequired,
      hideStepFromNav: PropTypes.bool,
      stepIcon: PropTypes.string,
      component: PropTypes.oneOfType([PropTypes.func, function (props, key, componentName, location, propFullName) {
        if (!isForwardRefComponent(props.component)) {
          return new Error(`Invalid prop ${propFullName} supplied to ${componentName}. Validation failed.`);
        }
      }]),
      stepProps: PropTypes.object,
    })
  ).isRequired
};

export default Wizard;