import React from "react";
// Redux
import { connect } from "react-redux";

import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import { find, filter } from "lodash";

import logo from "../assets/images/logo.png";
import back from "../assets/images/back.svg";
import check from "../assets/images/check.svg";

import { SINGLE_SCHEDULE_NAME, finishUrl } from '../config';

import Emitter from "helpers/emitter_helper";

import Wizard from "components/Wizard";

import Provincia from "./Steps/Provincia";
import Tramite from "./Steps/Tramite";
import Sucursal from "./Steps/Sucursal";
import Fecha from "./Steps/Fecha";
import Datos from "./Steps/Datos";
import Confirmacion from "./Steps/Confirmacion";

import { getBranches, getSchedules, createAppointment } from "store/actions";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      steps: [
        { stepNumber: 1, stepName: "Provincia",  component: Provincia, stepLabel: "Estado" },
        { stepNumber: 2, stepName: "Sucursal", component: Sucursal, stepLabel: "Sucursal" },
        { stepNumber: 3, stepName: "Fecha", component: Fecha, stepLabel: "Fecha" },
        { stepNumber: 4, stepName: "Datos Personales", component: Datos, stepLabel: "Datos Personales" },
        { stepNumber: 5, stepName: "Confirmación", hideStepFromNav: true, component: Confirmacion, stepLabel: "Confirmación" }
      ],
      success_msg: false,
      error_msg: false,
      currentStep: 0,
    };
  }

  componentDidMount() {
    this.props.getBranches();
    this.props.getSchedules(null, (result) => this.onGetSchedules(result));
  }

  componentWillUnmount() {
    Emitter.off('TYPE_UPDATED');
  }

  finish(allStates) {
    const tramites = filter(this.props.schedules, (s) => {
      //harcodeo unica agenda
      return s.name == SINGLE_SCHEDULE_NAME;
    }); 
    const tramite = filter(tramites, (s) => {
      return find(s.branches, { id: allStates['Sucursal'].branch });
    })[0];
    
    let payload = {
      branch: {
        id: allStates['Sucursal'].branch
      },
      schedule: {
        id: tramite.id
      },
      startAt: allStates['Fecha'].startAt,
      endAt: allStates['Fecha'].endAt,
      customer: {
        firstName: allStates['Datos Personales'].values.firstName,
        lastName: allStates['Datos Personales'].values.lastName,
        dni: allStates['Datos Personales'].values.passport,
        phone: allStates['Datos Personales'].values.phone,
        email: allStates['Datos Personales'].values.email,
      }
    }
    
    this.props.createAppointment(payload, (result) => this.onAppointmentCreate(result));
  }

  onAppointmentCreate(result) {
    if (result) {
      this.setState({ success_msg: true });
    } else {
      this.setState({ error_msg: true });
    }
  }

  onGetSchedules(result) {
    if (result) {
      if (this.props.match.params.tramite) {
        setTimeout(() => {
          Emitter.emit('TRAMITE_FROM_URL', this.props.match.params.tramite);
        }, 1500);
      }
    }
  }

  onCurrentStepChange(currentStep) {
    this.setState({ currentStep });
  }

  render() {
    return (
      <Container>
        <Row className="top-header justify-content-center pt-3 pb-3">
          <Col>
            <div className="justify-content-between d-flex align-items-center">
              {this.state.currentStep > 0 && !this.state.success_msg ?
              <Col className="font-weight-bold text-left back-button" onClick={() => { Emitter.emit('PREVIOUS_BUTTON_CLICK', true); }}>
                <img src={back} alt="Volver" className="img-fluid" /> {window.innerWidth > 400 ?
                'Volver'
              : undefined }
              </Col>
              : <Col></Col>}
              <Col className="text-center ml-md-4">
                <img src={logo} alt="Benavides" className="logo" />
              </Col> 
              <Col className="font-weight-bold text-right mr-4 turnero">
              {}
              </Col>
              
            </div>
          </Col>
        </Row>
        <Row className="wizard-body">
          <Col className="p-0">
            {!this.state.success_msg ? (
              <Wizard
                title=""
                steps={this.state.steps}
                headerTextCenter
                validate
                color="primary"
                finishButtonText="Confirmar cita"
                finishButtonClick={this.finish.bind(this)}
                type={this.state.type}
                currentStepChange={this.onCurrentStepChange.bind(this)}
              />
            ) : 
              <div className="p-5 d-flex justify-content-center">
                <Card className="confirmation-card-success">
                  <CardBody className="p-4">
                    <Row className="flex-column">
                      <div className="circle-check"><img src={check} className="img-fluid" /></div>
                      <h5>¡Tu cita fue confirmada!</h5>
                      <p>
                        Para tu tranquilidad, te reenviamos todos los datos de tu cita a tu casilla de email. ¡Gracias por elegirnos!
                      </p>
                      <Button
                        type="button"
                        className="btn-confirm"
                        onClick={() => { window.top.location = this.props.schedules.find(s => s.name === SINGLE_SCHEDULE_NAME).redirectAfterFinishUrl }}
                      >
                        Finalizar
                      </Button>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            }
            {this.state.error_msg ? (
              <SweetAlert
                title="Error!"
                error
                confirmBtnBsStyle="danger"
                confirmBtnText="Aceptar"
                onConfirm={() => this.setState({ error_msg: false })}
              >
                {this.props.error.apiMessage == 'API_APPOINTMENTS_CREATE_MAX_NUMBER_OF_APPOINTMENTS_PER_CUSTOMER_PER_DAY_REACHED' ? 'Se alcanzó el límite máximo establecido de citas por cliente por día.' : 'Ha ocurrido un error. Por favor, intente nuevamente'}
              </SweetAlert>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { schedules, error } = state.Home;
  return {
    schedules, error
  };
}

export default connect(mapStateToProps, {
  getBranches,
  getSchedules,
  createAppointment
})(Home);
