import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
// Redux
import { connect } from "react-redux";

import { Row, Col, Button } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Emitter from "helpers/emitter_helper";

import rightCaret from "../../assets/images/right-caret-white.svg";

class Datos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      values: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      },
      recaptchaToken: null,
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    if(!process.env.REACT_APP_RECAPTCHA_SITE_KEY) 
    console.warn("Missing re-captcha site key. Captcha is disabled");
  }

  isValidated() {
    return this.state.validated;
  }

  handleRecaptchaChange(recaptchaToken) {
    this.setState({ recaptchaToken });
  }

  render() {
    return (
      <React.Fragment>
        <Row>
            <Col>
              <h5><span className="step-number">4</span> <span className="step-description">Ingresá los datos del paciente</span></h5>
            </Col>
        </Row>
        <Formik
          validateOnMount={true}
          innerRef={this.formRef}
          initialValues={{
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().matches(/^[a-zA-Z ]+$/, {message: "No puede contener caracteres especiales o numéros"})
              .required('Ingresá el nombre del paciente'),
            lastName: Yup.string().matches(/^[a-zA-Z ]+$/, {message: "No puede contener caracteres especiales o numéros"})
              .required('Ingresá el apellido del paciente'),
            phone: Yup.string().matches(/^\d+$/, {message: "El telefono debe contener solo numéros"})
              .required('Ingresá el teléfono del paciente'),
            email: Yup.string()
              .email('El email es inválido')
              .required('Ingresá el email del paciente'),
          })}
          onSubmit={values => {
            console.log('values', values);
            if(this.state.recaptchaToken){
                this.setState({validated: true, values});
                Emitter.emit('DATA_COMPLETED', true);
                Emitter.emit('NEXT_BUTTON_CLICK', true);
            }
          }}>
           {({ errors, touched, values, submitCount }) => (
            <Form className="data-form">
              <Row>
                <Col>
                  <div className="instrucciones">
                    <span>Al momento de sacar una cita, recordá nombre y apellido <strong>de la persona que recibe el servicio.</strong></span>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4 mt-md-5">
                <Col xs={12} md={6}>
                  <div className="form-group">
                    <label>Nombre <span>*</span></label>
                    <Field
                      className={
                        "form-control" +
                        (errors.firstName && touched.firstName
                          ? " is-invalid"
                          : "")
                      }
                      name="firstName"
                      type="text"
                      
                    />
                    <ErrorMessage name="firstName" component="div" className="invalid-feedback" />
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="form-group">
                    <label>Apellido <span>*</span></label>
                    <Field
                      className={
                        "form-control" +
                        (errors.lastName && touched.lastName
                          ? " is-invalid"
                          : "")
                      }
                      name="lastName"
                      type="text"
                    />
                    <ErrorMessage name="lastName" component="div" className="invalid-feedback" />
                  </div>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col xs={12} md={6}>
                <div className="form-group">
                    <label>Email <span>*</span></label>
                    <Field
                      className={
                        "form-control" +
                        (errors.email && touched.email
                          ? " is-invalid"
                          : "")
                      }
                      name="email"
                      type="text"
                    />
                    <ErrorMessage name="email" component="div" className="invalid-feedback" />
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="form-group">
                    <label>Teléfono <span>*</span></label>
                    <Field
                      className={
                        "form-control" +
                        (errors.phone && touched.phone
                          ? " is-invalid"
                          : "")
                      }
                      name="phone"
                      type="text"
                    />
                    <ErrorMessage name="phone" component="div" className="invalid-feedback" />
                  </div>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col xs={12} md={6}>
                  {process.env.REACT_APP_RECAPTCHA_SITE_KEY && 
                    <div className="form-group">
                        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={this.handleRecaptchaChange.bind(this)} />
                        {
                            
                            !this.state.recaptchaToken && !!submitCount &&
                        <div className="invalid-feedback">Debe verificar el captcha</div>
                        }
                    </div>
                  }
                </Col>
                <Col xs={12} md={6}>
                  <div className="form-group">
                    <label></label>
                    <Button
                      type="submit"
                      className="btn-advance btn-confirm"
                    >
                      Avanzar a Confirmación <img src={rightCaret} />
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
          </Formik>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
  };
}

export default connect(mapStateToProps, {
}, null, { forwardRef: true })(Datos);

